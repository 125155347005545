import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Suspense, lazy } from 'react';
import Loading from './components/shared/components/Loading';

const MainComponent = lazy(() => import('./MainComponent'));

const Main = withAuthenticationRequired(MainComponent, {
  onRedirecting: () => <Loading />,
});

const MainComponentLazy = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Main />
    </Suspense>
  );
};

export default MainComponentLazy;
